import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "fira code",
  },
  palette: {
    mode: "dark",
    // 138 255 128
    primary: {
      main: '#8aff80',
    },
    secondary: {
      main: '#9580ff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#22212c',
    },
  },
});

export default theme;
